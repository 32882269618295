// 工资表
<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="日期" prop="yearMonth">
          <el-date-picker
            :disabled="true"
            style="width: 100%"
            v-model="dataForm.yearMonth"
            value-format="yyyy-MM"
            type="month"
            :clearable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="getNewData()" type="primary">获取最新业务数据</el-button>
          <el-button @click="batchRelease()" type="primary" :disabled="!dataListSelections.length">批量发放</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox wages-table">
      <el-table :data="dataList"
                :summary-method="getSummaries"
                show-summary
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :height="tableHeight"
                border size="small" v-loading="dataListLoading"
                @selection-change="selectionChangeHandle" style="width: 100%;" class="table-input" @cell-dblclick="tableDbEdit">
        <el-table-column type="selection" width="30" align="center" fixed="left"></el-table-column>
        <el-table-column prop="driverName" label="司机姓名" width="60" align="center" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column prop="entryDate" label="入职时间" width="75" align="center" :show-overflow-tooltip="true" fixed="left">
        </el-table-column>
        <el-table-column prop="dutyDay" label="出勤" width="40" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
            <el-input v-if="scope.row.dutyDay && scope.row.dutyDay.edit"
                      v-model="scope.row.dutyDay.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.dutyDay)">
            </el-input>
            <span v-else-if="scope.row.dutyDay">{{scope.row.dutyDay.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="actualDutyDay" label="实际出勤" width="60" :show-overflow-tooltip="true" fixed="left">
          <template slot-scope="scope">
              <el-input v-if="scope.row.actualDutyDay && scope.row.actualDutyDay.edit"
                        v-model="scope.row.actualDutyDay.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.actualDutyDay)">
              </el-input>
              <span>{{scope.row.actualDutyDay.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="baseWage" label="基本工资" width="60" :show-overflow-tooltip="true" class-name="green-cell" fixed="left">
        </el-table-column>
        <el-table-column prop="fullDutyFee" label="全勤奖" width="60" :show-overflow-tooltip="true" class-name="green-cell" fixed="left">
<!--          <template slot-scope="scope">-->
<!--              <el-input v-if="scope.row.fullDutyFee && scope.row.fullDutyFee.edit"-->
<!--                        v-model="scope.row.fullDutyFee.value"-->
<!--                        style="width: 100%"-->
<!--                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"-->
<!--                        @blur="changeData(scope.row, scope.row.fullDutyFee)">-->
<!--              </el-input>-->
<!--              <span v-else-if="scope.row.fullDutyFee">{{scope.row.fullDutyFee.value}}</span>-->
<!--          </template>-->
        </el-table-column>
        <el-table-column prop="royaltyFee" label="提成" width="60" :show-overflow-tooltip="true" class-name="green-cell">
        </el-table-column>
        <el-table-column prop="extraFee" label="加班费" width="60" :show-overflow-tooltip="true" class-name="green-cell">
          <template slot-scope="scope">
            <el-input v-if="scope.row.extraFee && scope.row.extraFee.edit"
                      v-model="scope.row.extraFee.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.extraFee, true)">
            </el-input>
            <span>{{scope.row.extraFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="loadFee" label="卸货费" width="60" :show-overflow-tooltip="true" class-name="green-cell">
        </el-table-column>
        <el-table-column prop="stayFee" label="住宿费" width="60" :show-overflow-tooltip="true" class-name="green-cell">
        </el-table-column>
        <el-table-column prop="rentSubsidyFee" label="房租补贴" width="60" :show-overflow-tooltip="true" class-name="green-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.rentSubsidyFee && scope.row.rentSubsidyFee.edit"
                        v-model="scope.row.rentSubsidyFee.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.rentSubsidyFee)">
              </el-input>
              <span v-else>{{scope.row.rentSubsidyFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hygieneFee" label="卫生考核" width="60" :show-overflow-tooltip="true" class-name="green-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.hygieneFee && scope.row.hygieneFee.edit"
                        v-model="scope.row.hygieneFee.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.hygieneFee)">
              </el-input>
              <span v-else>{{scope.row.hygieneFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="otherSubsidyFee" label="其他补贴" width="60" :show-overflow-tooltip="true" class-name="green-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.otherSubsidyFee && scope.row.otherSubsidyFee.edit"
                        v-model="scope.row.otherSubsidyFee.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.otherSubsidyFee)">
              </el-input>
              <span v-else>{{scope.row.otherSubsidyFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="shouldWageFee" label="应发工资" width="60" :show-overflow-tooltip="true" class-name="green-cell">
        </el-table-column>
        <el-table-column prop="leaveDay" label="请假天数" width="55" :show-overflow-tooltip="true" class-name="yellow-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.leaveDay && scope.row.leaveDay.edit"
                        v-model="scope.row.leaveDay.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.leaveDay)">
              </el-input>
              <span v-else>{{scope.row.leaveDay.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="leaveFee" label="请假扣款" width="60" :show-overflow-tooltip="true" class-name="yellow-cell">
        </el-table-column>
        <el-table-column prop="socialFee" label="代缴个人社保" width="80" :show-overflow-tooltip="true" class-name="yellow-cell">
          <template slot-scope="scope">
            <el-input v-if="scope.row.socialFee && scope.row.socialFee.edit"
                      v-model="scope.row.socialFee.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.socialFee)">
            </el-input>
            <span v-else>{{scope.row.socialFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="insuranceFee" label="出险扣款" width="60" :show-overflow-tooltip="true" class-name="yellow-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.insuranceFee && scope.row.insuranceFee.edit"
                        v-model="scope.row.insuranceFee.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.insuranceFee)">
              </el-input>
              <span v-else>{{scope.row.insuranceFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="otherDeductionFee" label="其他扣款" width="60" :show-overflow-tooltip="true" class-name="yellow-cell">
          <template slot-scope="scope">
              <el-input v-if="scope.row.otherDeductionFee && scope.row.otherDeductionFee.edit"
                        v-model="scope.row.otherDeductionFee.value"
                        style="width: 100%"
                        onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                        @blur="changeData(scope.row, scope.row.otherDeductionFee)">
              </el-input>
              <span v-else>{{scope.row.otherDeductionFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalDeductionFee" label="合计扣款" width="60" :show-overflow-tooltip="true" class-name="yellow-cell">
        </el-table-column>
        <el-table-column prop="actualWageFee" label="实发工资" width="60" :show-overflow-tooltip="true" class-name="final-cell">
        </el-table-column>

        <el-table-column prop="advanceWageFee" label="预支工资扣款" width="80" :show-overflow-tooltip="true" class-name="blue-cell">
          <template slot-scope="scope">
            <el-input v-if="scope.row.advanceWageFee && scope.row.advanceWageFee.edit"
                      v-model="scope.row.advanceWageFee.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.advanceWageFee)">
            </el-input>
            <span v-else>{{scope.row.advanceWageFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="surplusOutFee" label="剩余出车费" width="70" :show-overflow-tooltip="true" class-name="blue-cell">
          <template slot-scope="scope">
            <el-input v-if="scope.row.surplusOutFee && scope.row.surplusOutFee.edit"
                      v-model="scope.row.surplusOutFee.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.surplusOutFee)">
            </el-input>
            <span v-else>{{scope.row.surplusOutFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="regulationDeductionFee" label="违章扣款" width="60" :show-overflow-tooltip="true" class-name="blue-cell">
          <template slot-scope="scope">
            <el-input v-if="scope.row.regulationDeductionFee && scope.row.regulationDeductionFee.edit"
                      v-model="scope.row.regulationDeductionFee.value"
                      style="width: 100%"
                      onkeyup="value=value.replace(/[^0-9.-]/g, '')"
                      @blur="changeData(scope.row, scope.row.regulationDeductionFee)">
            </el-input>
            <span v-else>{{scope.row.regulationDeductionFee.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="totalDeduction" label="合计扣款" width="60" :show-overflow-tooltip="true" class-name="blue-cell">
        </el-table-column>
        <el-table-column prop="finalWageFee" label="最终实发" width="60" :show-overflow-tooltip="true" class-name="final-cell">
        </el-table-column>

        <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-input v-if="scope.row.remark && scope.row.remark.edit"
                      v-model="scope.row.remark.value"
                      style="width: 100%"
                      @blur="changeData(scope.row, scope.row.remark)">
            </el-input>
            <span v-else>{{scope.row.remark.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="regulation" label="违章出险" :show-overflow-tooltip="true">
             <template slot-scope="scope">
              <el-input v-if="scope.row.regulation && scope.row.regulation.edit"
                        v-model="scope.row.regulation.value"
                        style="width: 100%"
                        @blur="changeData(scope.row, scope.row.regulation)">
              </el-input>
              <span v-else>{{scope.row.regulation.value}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="发放情况" fixed="right" width="60" align="center"  :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 2" size="small" type="danger">未发放</el-tag>
            <el-tag v-if="scope.row.status == 1" size="small" type="success">已发放</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        yearMonth: null,
        driverName: null,
        mainId: null
      },
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      detailVisible: false,
      oldCellVal: '',
    }
  },
  components: {
  },
  created () {
    let row = this.$route.params.row
    this.dataForm.mainId = row.id
    this.dataForm.yearMonth = row.yearMonth
    this.getDataList()
    this.resetHeight()
  },
  mounted () {
    let that = this
    document.addEventListener('click', function () {
      if (!event.target.classList.contains('el-input__inner')) {
        that.dataList.forEach(item => {
          // let valueList = 'actualDutyDay,,fullDutyFee,royaltyFee,extraFee,loadFee,stayFee,rentSubsidyFee,hygieneFee,otherSubsidyFee,socialFee,leaveFee,advanceWageFee,surplusOutFee,otherDeductionFee,remark,regulation'
          let valueList = ['actualDutyDay', 'dutyDay', 'extraFee', 'rentSubsidyFee', 'hygieneFee', 'otherSubsidyFee', 'socialFee', 'leaveDay', 'advanceWageFee', 'surplusOutFee', 'otherDeductionFee','insuranceFee', 'regulationDeductionFee', 'remark', 'regulation']
          for (let i in item) {
            valueList.forEach(val => {
              if(val == i) {
                item[i] = {
                  value: item[i].value,
                  edit: false
                }
              }
            })
          }
        })
      }
    })
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    tableDbEdit (row, column, cell, event) {
      let valueList = ['actualDutyDay', 'dutyDay', 'extraFee', 'rentSubsidyFee', 'hygieneFee', 'otherSubsidyFee', 'socialFee', 'leaveDay', 'advanceWageFee', 'surplusOutFee', 'otherDeductionFee', 'insuranceFee','regulationDeductionFee', 'remark', 'regulation']
      valueList.forEach(val => {
        if (val === column.property) {
          this.oldCellVal = row[column.property].value
          if (row[column.property]) {
            row[column.property].edit = row.status === 2
          }
        }
      })
    },
    changeData (row, data, isUpdate) {
      if (this.oldCellVal != data.value) {
        if (!row.id) {
          this.$message.error('请获取最新数据')
        } else {
          let rowItem = JSON.parse(JSON.stringify(row))
          let valueList = ['actualDutyDay', 'dutyDay', 'extraFee', 'rentSubsidyFee', 'hygieneFee', 'otherSubsidyFee', 'socialFee', 'leaveDay', 'advanceWageFee', 'surplusOutFee', 'otherDeductionFee', 'insuranceFee','regulationDeductionFee', 'remark', 'regulation']
          valueList.forEach(item => {
            rowItem[item] = rowItem[item].value
          })
          rowItem.nextUpdateExtraFee = isUpdate
          this.$http({
            url: this.$http.adornUrl(`/driverWage/update`),
            method: 'POST',
            data: this.$http.adornData(rowItem)
          }).then(({ data }) => {
            if (data) {
              row.shouldWageFee = this.getValue(data.shouldWageFee)
              row.fullDutyFee = this.getValue(data.fullDutyFee)
              row.baseWage = this.getValue(data.baseWage)
              row.leaveFee = this.getValue(data.leaveFee)
              row.totalDeductionFee = this.getValue(data.totalDeductionFee)
              row.actualWageFee = this.getValue(data.actualWageFee)
              row.totalDeduction = this.getValue(data.totalDeduction)
              row.finalWageFee = this.getValue(data.finalWageFee)
              this.$message.success('修改成功')
              this.getTotal()
            }
          })
        }
      }
    },
    setDefaultDate () {
      let date = new Date()
      this.dataForm.yearMonth = this.dateFormatter(date, 'yyyy-MM')
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    resetHeight () {
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      window.setTimeout(() => {
        this.tableHeight = '67vh'
      }, 1000)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      // this.resetHeight()
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let valueList = 'actualDutyDay,dutyDay,extraFee,rentSubsidyFee,hygieneFee,otherSubsidyFee,socialFee,leaveDay,advanceWageFee,surplusOutFee,otherDeductionFee,insuranceFee,regulationDeductionFee'
        // 不可以修改的字段
        let list = 'shouldWageFee,baseWage,fullDutyFee,royaltyFee,loadFee,stayFee,totalDeductionFee,actualWageFee,leaveFee,totalDeduction,finalWageFee,regulation'
        const values = data.map(item => {
          if(column.property && list.indexOf(column.property) != -1) {
            return this.getValue(Number(item[column.property]))
          } else if (column.property && valueList.indexOf(column.property) != -1) {
            return this.getValue(Number(item[column.property].value))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计',
        '',
        '',
        totalSummary.dutyDay,
        totalSummary.actualDutyDay,
        totalSummary.baseWage,
        totalSummary.fullDutyFee,
        totalSummary.royaltyFee,
        totalSummary.extraFee,
        totalSummary.loadFee,
        totalSummary.stayFee,
        totalSummary.rentSubsidyFee,
        totalSummary.hygieneFee,
        totalSummary.otherSubsidyFee,
        totalSummary.shouldWageFee,
        totalSummary.leaveDay,
        totalSummary.leaveFee,
        totalSummary.socialFee,
        totalSummary.insuranceFee,
        totalSummary.otherDeductionFee,
        totalSummary.totalDeductionFee,
        totalSummary.actualWageFee,
        totalSummary.advanceWageFee,
        totalSummary.surplusOutFee,
        totalSummary.regulationDeductionFee,
        totalSummary.totalDeduction,
        totalSummary.finalWageFee,
        '',
        '',
        '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    getValue (value) {
      if (value == 0) {
        return ''
      } else {
        return value
      }
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getTotal () {
      this.$http({
        url: this.$http.adornUrl('/driverWage/findTotal'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'yearMonth': this.dataForm.yearMonth,
          'mainId': this.dataForm.mainId
        })
      }).then(({ data }) => {
        this.getAllSummaries(data)
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driverWage/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'mainId': this.dataForm.mainId,
          'driverName': this.dataForm.driverName,
          'yearMonth': this.dataForm.yearMonth
        })
      }).then(({ data }) => {
        if (data) {
          data.list.forEach(item => {
            let valueList = ['actualDutyDay', 'dutyDay', 'extraFee', 'rentSubsidyFee', 'hygieneFee', 'otherSubsidyFee', 'socialFee', 'leaveDay', 'advanceWageFee', 'surplusOutFee', 'otherDeductionFee','insuranceFee', 'regulationDeductionFee', 'remark', 'regulation']
            item.remark = item.remark || ''
            item.regulation = item.regulation || ''
            for (let i in item) {
              if (item[i] === 0) {
                item[i] = ''
              }
              valueList.forEach(val => {
                if (val == i) {
                  item[i] = {
                    value: item[i],
                    edit: false
                  }
                }
              })
            }
          })
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.summaryTotal || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 批量发放
    batchRelease () {
      let ids = []
      for (let i = 0; i < this.dataListSelections.length; i++) {
        if (this.dataListSelections[i].id && this.dataListSelections[i].status === 2) {
          ids.push(this.dataListSelections[i].id)
        }
      }
      if (ids.length === 0){
        this.$message.error('没有可发放的条目!')
        this.dataListLoading = false
        return
      }
      this.$http({
        url: this.$http.adornUrl('/driverWage/updateSend'),
        method: 'post',
        data: {
          'ids': ids,
          'yearMonth': this.dataForm.yearMonth,
          'mainId': this.dataForm.mainId
        }
      }).then(({ data }) => {
        if (data) {
          this.$message.success('发放成功')
          this.getDataList()
        } else {
          this.$message.success('发放失败')
        }
      })
    },
    // 获取数据列表
    getNewData () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/driverWage/updateBusinessData'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'mainId': this.dataForm.mainId,
          'yearMonth': this.dataForm.yearMonth
        })
      }).then(({ data }) => {
        this.getDataList()
      })
    },
    downLoad () {
      this.$http({
        url: this.$http.adornUrl(`/driverWage/export`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'mainId': this.dataForm.mainId,
          'driverName': this.dataForm.driverName,
          'yearMonth': this.dataForm.yearMonth
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '工资表明细.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    }
  }
}
</script>
<style lang="less">
span.searchUpload {
    display: inline-block;
    margin-left: 10px;
}
.wages-table {
  td .cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .el-input__inner {
    border-radius: 0 !important;
    border:0 !important;
    padding:0 5px !important;
    height: 24px !important;
    line-height: 24px !important
  }
}
</style>
